import React from "react";
import { FaYoutube, FaDiscord } from "react-icons/fa";
import Logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 gap-8 text-gray-300">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex self-center items-center">
          <img className="w-14" src={Logo} alt="" />
          <h1 className="w-full text-xl font-bold text-[#00df9a] uppercase">
            LeagueMentor
          </h1>
        </div>

        <div className="flex gap-8 justify-center my-6">
          <a href="https://www.youtube.com/channel/UCOMiBLiEyGf7Vpu5dulw9Pg">
            <FaYoutube size={30} />
          </a>
          <a href="https://discord.gg/WCk7U5CBb9">
            <FaDiscord size={30} />
          </a>
        </div>

        <p className="py-4 text-center w-max">Copyright © 2024. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
