import React from "react";
import ReactGA from "react-ga4";
import check from "../assets/checklistGREEN.png";
import checkBlue from "../assets/checklistDARKGREEN.png";

const Card = () => {
  // Function to handle button click and send the event to Google Analytics
  const handleClick = () => {
    ReactGA.event({
      category: 'engagement',
      action: 'click',
      label: 'Join the Waitlist',
    });
  };

  return (
    <div id="coaching" className="w-full py-[5rem] md:py-[8rem] px-8 bg-white">
      <div className="mb-20 space-y-6 px-8 md:px-0">
        <h2 className="text-center text-black text-4xl font-bold md:text-6xl">
          Join us <span className="mr-2 text-[#00df9a]">now</span>
        </h2>
      </div>
      <div className="max-w-[1240px] mx-auto flex flex-col md:flex-row justify-center gap-16">
        <div className="overflow-hidden bg-white w-full border md:w-[600px] flex flex-col py-8 my-8 rounded-lg hover:scale-105 duration-300 shadow-lg">
          <h2 className="text-3xl text-[#00df9a] md:text-4xl font-bold text-center py-12">
            Bootcamp
          </h2>
          <div>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              Weekly lectures non-role specific <span className="text-sm text-gray-500 ml-2">$35 x 4</span>
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              Live QnA on EVERY event
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              Recordings of all past lectures<span className="text-sm text-gray-500 ml-2">$500+</span>
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              Weekly group VOD review sessions <span className="text-sm text-gray-500 ml-2">$35 x 4</span>
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              Training Events, like matchup training
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={check} className="w-8 h-8 mr-4" alt="check" />
              20% discount on 1 on 1 sessions
            </p>
          </div>
          <button
            className="bg-[#00df9a] w-[250px] rounded-md mt-12 mb-3 mx-auto px-8 py-4 text-xl font-bold"
            onClick={handleClick}
          >
            <a href="https://2ly.link/1zgc3">
              Join the Waitlist
            </a>
          </button>
          <div className="text-center text-xl font-semibold text-black">
            $25.99/month
          </div>
        </div>
        <div className="bg-white w-full border md:w-[600px] flex flex-col py-8 my-8 rounded-lg hover:scale-105 duration-300 shadow-lg">
          <h2 className="text-3xl text-[#038039] md:text-4xl font-bold text-center py-12">
            1-on-1 Coaching
          </h2>
          <div>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={checkBlue} className="w-8 h-8 mr-4" alt="check" />
              Free consultation in the form of text
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={checkBlue} className="w-8 h-8 mr-4" alt="check" />
              Professional coaches with over 2000+ sessions in total
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={checkBlue} className="w-8 h-8 mr-4" alt="check" />
              Flexible scheduling weeks in advance
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={checkBlue} className="w-8 h-8 mr-4" alt="check" />
              In-depth analysis and personalized feedback
            </p>
            <p className="py-4 border-b mx-12 inline-flex items-center text-xl font-semibold">
              <img src={checkBlue} className="w-8 h-8 mr-4" alt="check" />
              "Homework" to take away after the sessions
            </p>
          </div>
          <button className="bg-[#038039] w-[250px] rounded-md mt-12 mb-3 mx-auto px-8 py-4 text-xl text-white font-bold">
            <a target="_blank" href="https://discord.gg/jxYBbbeZ6G">
              Get Coached Now
            </a>
          </button>
          <div className="text-center text-xl font-semibold text-black">
            $35/60 minutes
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
