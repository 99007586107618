import { useEffect } from "react"
import ReactGA from "react-ga4";
import "./App.css";
import About from "./components/About";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Review from "./components/Review";
import FreeContent from "./components/FreeContent";
import Premium from "./components/Premium";

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize("G-HX93WGTLEN");

    // Track the initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="">
      <Hero />
      <About />
      <FreeContent />
      <Premium />
      <Review/>
      <Card />
      <Footer/>
    </div>
  );
}

export default App;
