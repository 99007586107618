import React from "react";
import user1 from "../assets/user1.webp";
import user2 from "../assets/user2.webp";
import user3 from "../assets/user3.webp";
import user4 from "../assets/user4.webp";
import user5 from "../assets/user5.webp";
import arlequin from "../assets/arlequin.webp";
const Review = () => {
  return (
    <div
      className=" text-gray-600 pt-8 "
      id="reviews"
    >
      <div className="w-full py-16 text-black bg-red">
        <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3"></div>
        <div className="max-w-7xl mx-auto px-6 md:px- xl:px-6">
          <div className="mb-10 space-y-4 px-6 md:px-0">
            <h2 className=" text-center text-white text-2xl font-bold md:text-4xl">
              Words from our students
            </h2>
          </div>

          <div className="md:columns-2 lg:columns-3 gap-8 space-y-8">
            <div className=" md:h-[300px] p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4 items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={user1}
                  alt="user avatar"
                  width="400"
                  height="400"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    utemax
                  </h6>
                
                </div>
              </div>
              <p className="mt-4">
              It's scary how with just one class, in a matter of weeks I managed to go from Emerald 4 to Diamond 4, with these lessons and a little consistency in games it's possible to climb easily friends, trust the process
              </p>
            </div>

            <div className=" md:h-[300px]  p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4 items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={arlequin}
                  alt="user avatar"
                  width="200"
                  height="200"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    arlequin
                  </h6>
                  {/* <p className="text-sm text-gray-500">
                    Marketing
                  </p> */}
                </div>
              </div>
              <p className="mt-4">
              Pretty straightforward feedback and analysis, I learnt things that I thought were totally irrelevant, that were in fact super important, about my gameplay, my decisions and more<br></br> 5/5</p>
            </div>

            <div className=" md:h-[300px] p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4 items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={user2}
                  alt="user avatar"
                  width="200"
                  height="200"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    Cogito Ergo Sum
                  </h6>
                
                </div>
              </div>
              <p className="mt-4">
              Found this channel randomly and never felt so lucky, the lesson from Sagittarius was eye opening, glad to be part of this community!
              </p>
            </div>

            <div className=" md:h-[300px] p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4 items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={user3}
                  alt="user avatar"
                  width="200"
                  height="200"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    BunIzzet
                  </h6>
              
                </div>
              </div>
              <p className="mt-4">
              your ward bootcamp REALLY helped!!! ive been getting so many more objectives and avoiding ganks, and as viktor its good to be aware of ganks!!!
              </p>
            </div>

            <div className=" md:h-[300px] p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4">
                <img
                  className="w-12 h-12 rounded-full"
                  src={user4}
                  alt="user avatar"
                  width="200"
                  height="200"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    Kaboss
                  </h6>
                </div>
              </div>
              <p className="mt-4">
              You've heard of fast-acting cold medicine. Have you heard of fast-acting League coaching? (from E3 to D4 50LP)
              </p>
            </div>

            <div className=" md:h-[300px] p-8 border border-gray-100 rounded-3xl bg-white  shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div className="flex gap-4 items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={user5}
                  alt="user avatar"
                  width="400"
                  height="400"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-700 ">
                    Ainz Ooal Gown
                  </h6>
                
                </div>
              </div>
              <p className="mt-4 ">
              So I've played league since season 7 and I've never seen diamond but all my friends were always dissing me "haha you can't get diamond". Well in season 13 because of Sagittarius I have finally done it. Thank you so much. Now...time for masters.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
