import React from "react";

const WhatWeDo = () => {
  return (
    <div className="w-full py-[3rem] md:py-[5rem] px-4 " id="what-we-do">
      <div className="mb-20 space-y-4 px-6 md:px-0">
        <h2 className="text-center text-white text-3xl font-bold uppercase md:text-5xl">
          What We Do
        </h2>
      </div>
      <div className="max-w mx-auto flex flex-col md:flex-row justify-center gap-8 ">
        <div className="overflow-hidden  w-full h-full md:w-[700px] flex flex-col py-4 my-4 rounded-lg">
          <div className="flex flex-col items-center">
            <div className="w-full h-64 md:h-[400px]">
              <iframe
                className="w-full h-full rounded-3xl"
                src="https://www.youtube.com/embed/UCMJfHLIZ5w"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
            <p className="text-2xl font-medium  text-white mt-4">
              Find Free Content and VODs on our <a href="https://www.youtube.com/channel/UCOMiBLiEyGf7Vpu5dulw9Pg" className="underline text-green-400">channel</a>
            </p>
          </div>
        </div>
        <div className="w-full md:w-[800px] flex flex-col py-8 my-4 rounded-lg md:pl-16">
          <p className="text-xl font-bold text-white mt-8 mb-8 md:text-4xl">
            • Monthly Free Lectures on our <a href="https://discord.gg/WCk7U5CBb9" className="underline text-green-400">Discord</a>
          </p>
          <p className="text-xl font-bold text-white mt-8 mb-8 md:text-4xl">
            • Premium Bootcamps
          </p>
          <p className="text-xl font-bold text-white mt-8 mb-8 md:text-4xl">
            • 1 on 1 Sessions
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
