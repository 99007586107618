import React from "react";
import lectureImg from "../assets/lecture.png";
import vodreview from "../assets/vodreview.png";
import OneOnOne from "../assets/1on1.png";


const PremiumShowcase = () => {
  return (
    <div className="bg-white w-full py-[3rem] md:py-[5rem] px-4" id="what-we-do">
      <div className="mb-20 space-y-4 px-6 md:px-0">
        <h2 className="text-center text-black text-3xl font-bold uppercase md:text-5xl">
          Premium Bootcamp
        </h2>
      </div>

      <div className="max-w mx-auto flex flex-col md:flex-row justify-center gap-8">
        <div className="overflow-hidden w-full h-full md:w-[700px] flex flex-col py-4 my-4 rounded-lg">
          <div className="flex flex-col items-center">
            <div className="w-full h-64 md:h-[400px]">
              <img
                className="w-full h-full rounded-3xl object-cover"
                src={lectureImg}
                alt="Premium Bootcamp"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[800px] flex flex-col items-center justify-center my-4 rounded-lg md:pl-16">
          <p className="text-2xl font-bold text-black mt-8 mb-10 pb-10 md:text-4xl md:leading-[1.50]">
            Weekly <span className="mr-2 text-[#00df9a]">1 hour+</span>lectures where we teach <span className="mr-2 text-[#00df9a]">game changing concepts</span> for every role
          </p>
        </div>
      </div>

      <div className="max-w  mt-10 mx-auto flex flex-col md:flex-row-reverse justify-center gap-8">
        <div className="overflow-hidden w-full h-full md:w-[700px] flex flex-col py-4 my-4 rounded-lg">
          <div className="flex flex-col items-center">
            <div className="w-full h-64 md:h-[400px]">
              <img
                className="w-full h-full rounded-3xl object-cover"
                src={vodreview}
                alt="Premium Bootcamp"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[800px] flex flex-col items-center justify-center my-4 rounded-lg md:pr-16">
          <p className="text-2xl font-bold text-black mt-8 mb-10 pb-10 md:text-4xl md:leading-[1.50]">
            Spend time with challenger coaches during <span className="mr-2 text-[#00df9a]">weekly group VOD reviews</span> everyone is guaranteed <span className="mr-2 text-[#00df9a]">at least one!</span>
          </p>
        </div>
      </div>

      <div className="max-w mt-10 mx-auto flex flex-col md:flex-row justify-center gap-8">
        <div className="overflow-hidden w-full h-full md:w-[700px] flex flex-col py-4 my-4 rounded-lg">
          <div className="flex flex-col items-center">
            <div className="w-full h-64 md:h-[400px]">
              <img
                className="w-full h-full rounded-3xl object-cover"
                src={OneOnOne}
                alt="Premium Bootcamp"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[800px] flex flex-col items-center justify-center my-4 rounded-lg md:pl-16">
          <p className="text-2xl font-bold text-black mt-8 mb-10 pb-10 md:text-4xl md:leading-[1.50]">
            Up to <span className="mr-2 text-[#00df9a]">20% off</span> on individual 1 on 1 sessions with <span className="mr-2 text-[#00df9a]">our coaches</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PremiumShowcase;
