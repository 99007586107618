import React from "react";
import { ReactTyped } from "react-typed";
import showcase from "../assets/showcase.mp4";
import Navbar from "../components/Navbar";

const Hero = () => {
  return (
    <div className="hero"> <Navbar />
      <div className="absolute bg-black h-screen w-full opacity-60"></div>
      <video loop autoPlay muted>
        <source src={showcase} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
     
      <div className="hero-content px-4 md:px-0">
        <div className="text-white ">
          <div className="max-w-[800px] w-full h-[400px] mx-auto text-center flex-col justify-center">
            <p className="text-[#00df9a] font-bold text-3xl p-1">Expert coaching</p>
            <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 uppercase">
              For players of all levels.
            </h1>
            <div className="flex justify-center items-center">
              <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
                We coach
              </p>
              <ReactTyped
                className="md:text-5xl sm:text-4xl text-xl font-bold pl-2 md:pl-4"
                strings={["Junglers", "Top Laners","AD Carries","Mid Laners","Supports"]}
                typeSpeed={120}
                backSpeed={140}
                loop
              />
            </div>
            <div className="mb-8"></div>
            <a
              target="_blank"
              href="https://discord.gg/WCk7U5CBb9"
              className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 px-8 text-black"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
