import React from "react";
import logo from "../assets/logo.svg"

const Logo = () => {
  return (
    <div className="flex items-center">
        <img className="w-14" src={logo} alt="" />
      <h1 className="w-full text-xl font-bold text-[#00df9a] uppercase">
        LeagueMentor
      </h1>
    </div>
  );
};

export default Logo;
