import React from "react";
import award from "../assets/award.svg";
import trophy from "../assets/trophy.svg";

const Analytics = () => {
  return (
    <div
      id="about"
      className="w-full relative overflow-hidden md:h-screen flex flex-col justify-center bg-white"
    >
      <div className="custom-background absolute w-full h-full opacity-[1%]"></div>
      <img
        className="rotate-12 absolute -left-10 md:left-4 top-0 w-[100px] md:w-[220px] lg:w-[125px] mx-auto my-4"
        src={award}
        alt="award"
      />
      <img
        className="-rotate-12 absolute -right-14 md:-right-0 bottom-0 w-[100px] md:w-[220px] lg:w-[125px] mx-auto my-4"
        src={trophy}
        alt="trophy"
      />
      <div className="w-full relative flex flex-col justify-center bg-transparent py-0 h-full px-4">
        <h1 className="md:max-w-5xl mx-auto md:text-4xl lg:text-6xl text-center sm:text-3xl text-4xl font-bold py-2 mb-8 uppercase text-black">
          What are we
        </h1>
        <div className="relative text-black z-20 max-w-[1240px] mx-auto items-center text-center">
          <ul className="space-y-8 list-none md:text-4xl text-left font-bold leading-snug">
            <li className="flex items-center">
              <span className="mr-2 text-[#00df9a]">•</span>
              <span className="text-black">We are a Discord Operated Coaching Platform</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-[#00df9a]">•</span>
              <span className="text-black">Our coaches are <span className="text-[#00df9a]">multi season and role</span> trained challengers</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-[#00df9a]">•</span>
              <span className="text-black">We believe in teaching but also <span className="text-[#00df9a]">applying concepts</span></span>
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-[#00df9a]">•</span>
              <span className="text-black"><span className="text-[#00df9a]">Every role and rank</span> is welcome</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
